<!--
 * @Author: xiaziwen
 * @Date: 2021-01-06 16:09:28
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-06 16:30:58
 * @FilePath: \crm-education\src\App.vue
-->
<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  name: 'App',
  data() {
    return {
      locale: zhCN
    }
  },
  watch: {
    $route(val) {
      const role = localStorage.getItem('userRole')
      if (role === 'STUDENT') {
        document.title = '学习空间'
      } else if (role === 'TEACHER') {
        document.title = '教师空间'
      } else if (val.meta.title) {
        document.title = val.meta.title
      }
    }
  }
}
</script>
