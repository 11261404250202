/*
 * @Author: xiaziwen
 * @Date: 2021-01-06 16:39:09
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-02-03 16:54:44
 * @FilePath: \crm-education\src\router\routes.js
 */
export default [
  {
    path: '/',
    redirect: '/protal'
  },
  {
    path: '/protal',
    name: 'protal',
    component: () => import('@/layouts/Protal'),
    children: [
      {
        path: '/protal',
        name: 'protal_home',
        component: () => import('@/views/protal/home')
      },
      {
        path: '/protal/list',
        name: 'protal_list',
        component: () => import('@/views/protal/list')
      },
      {
        path: '/protal/list/detail',
        name: 'protal_list_detail',
        component: () => import('@/views/protal/list/detail')
      }
    ]
  },
  {
    path: '*',
    name: 'error_404',
    component: () => import('@/views/common/error/404.vue')
  }
]
