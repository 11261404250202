/*
 * @Author: xiaziwen
 * @Date: 2021-01-06 16:09:28
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-02-03 15:52:39
 * @FilePath: \crm-education\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import helper from '@/utils/helper'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    userRole: null,
    siderWidth: 200,
    menuKeyPath: ['/protal'],
    columnsInfo: [],
    domainSettings: null,
    noReadMsg: 0,
  },
  getters: {
    token(state) {
      return state.user.accessToken
    },
    userId(state) {
      return (state.userRole === 'STUDENT' || state.userRole === 'STUDLIVE') ? state.user.studId : state.user.teacherId
    },
    userInfo(state) {
      return state.user
    }
  },
  mutations: {
    setSiderWidth(state, w) {
      state.siderWidth = w
    },
    setRole(state, val) {
      state.userRole = val
    },
    setUser(state, user) {
      console.log(user)
      state.user = user
    },
    setMenuKeyPath(state, path) {
      state.menuKeyPath = path
    },
    setColumnsTypeCode(state, code) {
      state.columnsTypeCode = code
    },
    setColumnsInfo(state, info) {
      state.columnsInfo = info
    },
    setDomainSettings(state, settings) {
      state.domainSettings = settings
    },
    setNoReadMsgCount(state, count) {
      state.noReadMsg = count
    },
    setIsShowWxLogin(state,val) {
        state.isShowWxLogin = val
    }
  },
  actions: {
    async loginSuccess(ctx, { user, role }) {
      if (user && user.accessToken) {
        helper.clearUserInfo()
        ctx.commit('setUser', user)
        await helper.setUserInfo({ ...user, role })
      }
    }
  },
  modules: {}
})
