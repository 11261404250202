export default {
  clearUserInfo() {
    const localKeys = [
      'accessToken',
      'userRole',
      'userId',
      'studentName',
      'studentImg',
      'issue',
      'ouchFirstLogin',
      'isCurrentTerm'
    ]
    localKeys.forEach(item => {
      localStorage.removeItem(item)
    })
  },
  getUserInfo(field) {
    let result = {}
    const infoKeys = ['accessToken', 'userRole', 'userId', 'studentName', 'studentImg', 'isCurrentTerm']
    infoKeys.forEach(item => {
      result[item] = localStorage.getItem(item) || ''
    })
    if (field) {
      return result[field]
    } else {
      return result
    }
  },
  setUserInfo(data) {
    console.log(data)
    // studentName:studName-前端自定义字段:后端返回字段
    const infoKeys = [
      'userId:studId',
      'studentName:studName',
      'studentImg:studImg',
      'accessToken',
      'userRole:role',
      'issue',
      'ouchFirstLogin',
      'isCurrentTerm'
    ]
    const setLocal = fieldArray => {
      if (fieldArray.length > 1) {
        typeof data[fieldArray[1]] !== undefined && localStorage.setItem(fieldArray[0], data[fieldArray[1]])
      } else {
        typeof data[fieldArray[0]] !== undefined && localStorage.setItem(fieldArray[0], data[fieldArray[0]])
      }
    }
    infoKeys.forEach(item => {
      const matchField = item.split(':')
      if (item === 'ouchFirstLogin') {
        typeof data['ouchFirstLogin'] === 'number' && setLocal(matchField)
      } else {
        setLocal(matchField)
      }
    })
  },
  updateUserInfo(field, data) {
    localStorage.setItem(field, data)
  },
  getStudentId() {
    localStorage.getItem('userId')
  }
}
