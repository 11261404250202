/*
 * @Author: xiaziwen
 * @Date: 2021-01-06 16:09:28
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-20 18:13:16
 * @FilePath: \crm-education\src\main.js
 */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router/protal-router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import '@/assets/css/index.scss'

import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)
Vue.use(Antd)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
